.text-editor-style {
    width: 100%;
    min-height: 160px;
    max-height: 390px;
    height: 100%;
}

.text-editor-style > .MuiTiptap-FieldContainer-notchedOutline {
    width: 100%;
    border: none !important;
    height: auto;
}

.text-editor-style > .MuiTiptap-RichTextContent-root {
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 24px 16px;
    margin-top: 16px;
    overflow-y: auto;
    height: calc(100% - 74px); 
}
 
.text-editor-style > .MuiTiptap-RichTextContent-root > .ProseMirror {
    min-height: 160px;
    max-height: 300px;
    height: 100%;
}

.text-editor-style > .MuiTiptap-MenuBar-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    height: auto;
}