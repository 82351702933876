body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Target horizontal scrollbar specifically */
::-webkit-scrollbar:horizontal {
  height: 6px;
  /* Ensure the horizontal scrollbar matches the vertical one */
}

/* Track */
::-webkit-scrollbar-track {
  background: #0d48a133;
  width: 6px;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0D47A1;
  border-radius: 6px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #093577;

}

* {
  box-sizing: border-box;
}